.padding-top-4 {
  padding-top: 4px;
}

.padding-top-8 {
  padding-top: 8px;
}

.padding-top-12 {
  padding-top: 12px;
}

.padding-top-16 {
  padding-top: 16px;
}

.padding-top-24 {
  padding-top: 24px;
}

.padding-top-32 {
  padding-top: 32px;
}

.padding-bottom-4 {
  padding-bottom: 4px;
}

.padding-bottom-8 {
  padding-bottom: 8px;
}

.padding-bottom-12 {
  padding-bottom: 12px;
}

.padding-bottom-16 {
  padding-bottom: 16px;
}

.padding-bottom-24 {
  padding-bottom: 24px;
}

.padding-bottom-32 {
  padding-bottom: 32px;
}
/* Screen B-5 and B-10 */
.padding-right-56 {
  padding-right: 56px;
}

.margin-top-4 {
  margin-top: 4px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-12 {
  margin-top: 12px;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-top-24 {
  margin-top: 24px;
}
/* D12 screen */
.margin-top-26 {
  margin-top: 26px;
}
.margin-top-29 {
  margin-top: 29px;
}
.margin-top-32 {
  margin-top: 32px;
}
.margin-top-35 {
  margin-top: 35px;
}
/* D2 screen */
.margin-top-72 {
  margin-top: 72px;
}
/* D2 screen */
.margin-top-55 {
  margin-top: 55px;
}
/* A-3 screen */
.margin-top-240 {
  margin-top: 240px;
}
/* A-12 screen */
.margin-top-6 {
  margin-top: 6px;
}
/* A-12 screen */
.margin-top-51 {
  margin-top: 51px;
}
/* A-12 screen */
.margin-top-24 {
  margin-top: 24px;
}
/* A-12 screen */
.margin-top-83 {
  margin-top: 83px;
}
/* B-1 screen */
.margin-top-108 {
  margin-top: 108px;
}
.margin-bottom-4 {
  margin-bottom: 4px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-12 {
  margin-bottom: 12px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.margin-bottom-32 {
  margin-bottom: 32px;
}

.margin-left-2 {
  margin-left: 2px;
}

.margin-left-4 {
  margin-left: 4px;
}

.margin-left-8 {
  margin-left: 8px;
}
.margin-left-24 {
  margin-left: 24px;
}
.margin-right-20 {
  margin-right: 20px;
}
.margin-right-24 {
  margin-right: 24px;
}
.margin-top-57 {
  margin-top: 57px;
}
/* caution plate capture button */
.margin-top-101 {
  margin-top: 101px;
}
.badge-red {
  color: rgba(229, 92, 92, 0.8);
  border: 1px solid rgba(229, 92, 92, 1);
  border-radius: 3px;
  display: inline;
  padding: 4px;
  font-size: 12px;
}

.text-area-bordered {
  border-radius: "8px";
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(31, 31, 31, 1);
}

.text-area-bordered:focus {
  border-color: "red";
  color: "rgba(255, 255, 255, 1)";
}
.text-decoration:hover {
  text-decoration: underline;
}
.bg-transperent {
  background: rgba(0, 0, 0, 0) !important;
}

/* For B-5, B-10 */

.accordian-content-input-padding {
  padding: 16px 0px 8px 32px;
}

.margin-none {
  margin: 0;
}

.accordian-header-padding {
  padding: 13px 0 17px 0;
}

.ps-mt-c-2 {
  margin-top: 140px;
}

/* For B-12 */

.rc-mt-btn {
  margin-top: 240px !important;
}

/* For B-6 */

.lenl-mt-1 {
  margin-top: 200px !important;
}

.lenl-mt-btn {
  margin-top: 218px;
}

.fil-m-btn {
  margin-top: 44px;
  margin-bottom: 24px;
}

/* B-7 Screens */
.b7-comment {
  background-color: rgba(31, 31, 31, 1);
  border-radius: 8px;
  padding: 10px 12px;
  margin-bottom: 16px;
  line-break: anywhere !important;
}

.pr-90 {
  padding-right: 90px;
}
.padding-right-16 {
  padding-right: 16px;
}
/* C-3 c-4 screen */
.border-radius-16 {
  border-radius: 16px;
}

/* C-5 screen */
.mpc-mt-1 {
  margin-top: 24px;
}

.mpc-mt-btn {
  margin-top: 111px;
}

/* c-1 screen */

.margin-top-48 {
  margin-top: 48px;
}
/* used in c-2 and A-12 */
.textBreak {
  word-break: break-all;
}
.font-color {
  color: #ffffff !important;
}
@media only screen and (max-width: 900px) {
  .accordian-content-input-padding {
    padding: 13px 0;
  }

  .ps-mt-c-2 {
    margin-top: 24px;
  }

  /* For B-12 */

  .rc-mt-btn {
    margin-top: 160px !important;
  }

  /* For B-6 */

  .lenl-mt-1 {
    margin-top: 40px;
  }

  .lenl-mt-btn {
    margin-top: 138px;
  }

  .fil-m-btn {
    margin-top: 19px;
    margin-bottom: 24px;
  }

  /* B-7 Screen */
  .pr-90 {
    padding-right: 0;
  }

  /* C-5 screen */
  .mpc-mt-1 {
    margin-top: 0px;
  }

  .mpc-mt-btn {
    margin-top: 160px;
  }
}
