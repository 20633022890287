@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP");

body {
  font-family: "Noto Sans JP" !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px rgba(31, 31, 31, 1) inset !important;
  -webkit-text-fill-color: rgba(255, 255, 255, 1) !important;
}
.px-14 {
  padding-left: 14px;
  padding-right: 14px;
}

.pr-2 {
  padding-right: 2px;
}

.align-center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.navBar-title-text {
  font-size: 11px;
}

.signup .MuiFormControl-root {
  width: 100% !important;
}

.signup input {
  padding: 12px 14px !important;
  width: 100% !important;
  /* border-radius: 5px !important; */
}

.signup input {
  width: 100% !important;
}

.signup .css-hdw1oc {
  float: right !important;
}

.signup .MuiSelect-select {
  padding: 12px 14px !important;
}

/* .signup .MuiTypography-root, */
.signupConfirm .MuiTypography-root {
  color: #ffffff !important;
  opacity: 80% !important;
}

.signup-code-btn {
  background-color: #1f1f1f !important;
  width: 100% !important;
  color: grey !important;
}

.signup .required {
  color: #e55c5c !important;
}

/* It will affect to display image full on screen */

/* .signup .MuiAvatar-img {
  width: 100% !important;
  height: 100% !important;
} */

.signup .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #e55c5c !important;
}

.registerEmail .MuiFormControl-root {
  width: 100% !important;
}

.registerEmail input,
.registerEmail textarea {
  padding: 6.5px 14px !important;
  width: 100% !important;
}

.registerEmail input,
.registerEmail textarea,
.registerEmail button {
  background-color: #1f1f1f;
  width: 100%;
  color: grey;
  font-size: 16px;
  font-weight: 700;
}

.registerEmail .css-q2lp9a-MuiInputBase-root-MuiOutlinedInput-root {
  background-color: #1f1f1f !important;
}

.registerEmail .css-hdw1oc {
  float: right !important;
}

.registerEmail .MuiOutlinedInput-notchedOutline {
  top: 0px !important;
}

.registerEmail .MuiOutlinedInput-root {
  width: 100% !important;
  background-color: #1f1f1f !important;
}

.registerEmail .css-v3zyv7-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6.5px 14px;
  color: grey;
}

.registerEmail .MuiTypography-root,
.registerEmailConfirm .MuiTypography-root,
.registerEmailComplete .MuiTypography-root {
  opacity: 80% !important;
  font-size: 14px !important;
}

.registerEmail-code-btn {
  background-color: #1f1f1f !important;
  width: 100% !important;
  color: grey !important;
}

.cursor-pointer-custom {
  cursor: pointer !important;
}

.border-bottom-custom {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.2) !important;
}

.select-list:hover {
  background-color: rgb(39, 39, 39) !important;
}

.typeSearch button {
  /* background-color: #1F1F1F !important; */
  width: 100% !important;
  color: #ffffff !important;
  opacity: 0.8 !important;
}

Footer {
  bottom: 0;
  width: 100%;
}

#body-custom {
  padding: 10px;
  padding-bottom: 60px;
}

.footer .Link {
  color: #ffffff !important;
  opacity: 80% !important;
}

.border-bottom-custom-car-select {
  border-bottom: 1px solid #262626 !important;
  border-bottom-width: 0.5px !important;
  border-bottom-style: solid !important;
  border-bottom-color: #262626 !important;
}
/* Image fixes */
.image-height-126 {
  width: 168px;
  height: 126px;
  border-radius: 8px;
  object-fit: cover;
}
.errorImage {
  width: 100%;
  height: 126px;
  border-radius: 8px;
  padding: 22.5%;
  background: rgba(31, 31, 31, 1);
}
.image-height-131 {
  width: 161px;
  height: 131px;
  border-radius: 8px;
  object-fit: cover;
}

.responsived-block {
  width: 100%;
  height: 126px;
  border-radius: 8px;
}

.responsive-product-detail {
  width: 100%;
  height: 168;
  border-radius: 8px;
}




.responsive-custom {
  width: 100%;
  border-radius: 8px;
  height: 126px;
  object-fit: cover;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ProductPreview-soldOut {
  width: 112px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 15px;
  left: 15px;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  background: #e02d2d;
  border-radius: 3px;
}
.product-suggestion button {
  background-color: #1f1f1f !important;
  color: grey !important;
  font-weight: 700;
  width: 100% !important;
  border-color: grey;
}

/* CSS for common fontsize & and font color */
.font-size-24 {
  font-size: 1.5rem !important;
}

.font-size-18 {
  font-size: 1.125rem !important;
}

.font-size-16 {
  font-size: 1rem !important;
}

.font-size-14 {
  font-size: 0.875rem !important;
}

.font-size-13 {
  font-size: 0.813rem !important;
}

.font-size-12 {
  font-size: 0.75rem !important;
}

.font-size-11 {
  font-size: 0.688rem !important;
}

.font-size-10 {
  font-size: 10px !important;
}

.opacity-100 {
  opacity: 100% !important;
}

.opacity-80 {
  opacity: 80% !important;
}

.opacity-60 {
  opacity: 60% !important;
}

.opacity-40 {
  opacity: 40% !important;
}

.opacity-20 {
  opacity: 20% !important;
}

.background-orange {
  background: #e02d2d !important;
}

.font-color-white {
  color: #ffffff;
}

.font-color-red {
  color: rgba(230, 0, 18, 1);
}

.font-color-orange {
  color: rgba(229, 92, 92, 1);
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.btn-lg-grey {
  background: #3d3d3d !important;
  color: #ffffff !important;
  padding: 0.8rem 2.5rem !important;
  border-radius: 0.6rem !important;
  width: 100% !important;
  font-size: 1rem !important;
}

.btn-lg-orange {
  background: #e02d2d !important;
  color: #ffffff !important;
  padding: 0.5rem 2.5rem !important;
  border-radius: 0.6rem !important;
  width: 100% !important;
  font-size: 1rem !important;
}

.custom-border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.orange-underline:hover {
  text-decoration: underline;
  color: rgba(229, 92, 92, 1);
}

.orange-underline {
  color: rgba(229, 92, 92, 1);
}

.btn-grey:disabled {
  background: #333333 !important;
}

.btn-sm-grey {
  background: #3d3d3d !important;
  color: #ffffff !important;
  padding: 0.4rem 2rem !important;
  border-radius: 0.6rem !important;
}

.btn-md-grey {
  background: #3d3d3d !important;
  color: #ffffff !important;
  padding: 0.5rem 1rem !important;
  border-radius: 0.6rem !important;
  min-width: 16rem !important;
  font-size: 1rem !important;
}

.label-required {
  font-size: 11px !important;
  color: rgba(229, 92, 92, 1) !important;
  margin-left: 12px !important;
  margin-top: 0.1rem !important;
}

.input-y-margin {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.icon-size-48 {
  width: 3rem !important;
  height: auto !important;
}

.error-message {
  font-size: 12px;
  color: #e60012;
  font-weight: 600;
  opacity: 80%;
}

.orange-chip-outline {
  background: rgba(255, 255, 255, 0) !important;
  color: #e55c5c !important;
  border-radius: 2px !important;
  border: 1px solid #e55c5c !important;
  margin-top: 0.375rem;
}

.grey-chip {
  background: #3d3d3d;
  color: white;
  border-radius: 2px;
}

.cursor-pointer {
  cursor: pointer;
}

.mandatory-field {
  color: #e55c5c;
  margin-left: 5%;
  font-size: 11px;
  padding-top: 1.5%;
}

.mandatory-field-confirm {
  color: #e55c5c;
  margin-left: 5%;
  font-size: 12px;
  padding-top: 0.5%;
}

.font-weight-bold {
  font-weight: bold;
}

.header-clickable-text {
  display: flex !important;
}

.sidebar-bg {
  background: rgba(255, 255, 255, 0.16);
  min-width: 250px;
}
/* fixed Navbar */
.fixedHeader {
  position: sticky;
  top: 0;
  z-index: 1;
}
/* Navbar link hovering */
.headerLink:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
/* Sidebar menu hovering */
.sidebar-menu-hover:hover {
  background: rgba(0, 0, 0, 0.4);
  text-decoration: none;
  color: white;
}
/* Breadcrumb Link and footer link hover */
.link-hover:hover {
  color: rgba(255, 255, 255, 1);
  text-decoration: underline;
}

.link-hover {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}

.margin-top-24 {
  margin-top: 24px;
}

/* Link Hover For Red Text */
.link-hover-red:hover {
  color: rgba(229, 92, 92, 1);
  text-decoration: underline;
}

.delete-icon-btn:hover {
  color: rgba(255, 255, 255);
  text-decoration: underline;
}

.border-bottom-sidebar-links {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.border-top-sidebar-links {
  border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.navbar-popover-width {
  width: 30%;
}
.popover-scroll::-webkit-scrollbar {
  display: none;
}

.font-size-18-16-variable {
  font-size: 18px !important;
}

.font-size-16-14-variable {
  font-size: 16px !important;
}

.navbar-responsive-padding {
  padding: 36px 36px 36px 36px;
}

@media only screen and (max-width: 600px) {
  .navbar-popover-width {
    width: 80%;
  }
}

@media only screen and (max-width: 480px) {
  .header-clickable-text {
    display: none !important;
  }
}

@media only screen and (max-width: 480px) {
  .header-menu-web-icon {
    display: none !important;
  }
}

@media only screen and (min-width: 480px) {
  .header-menu-mobile-icon {
    display: none !important;
  }
}

.notification-bell {
  height: 1.4rem !important;
  width: 1.4rem !important;
  fill: #ffffff;
}

.footer-web-border {
  color: rgba(255, 255, 255, 0.2);
}

@media only screen and (max-width: 1023px) {
  .footer-web-border {
    border-right: none;
  }
}

.text-red {
  color: #e55c5c;
}

.font-weight-600 {
  font-weight: 600;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-20 {
  font-size: 1.25rem !important;
}

.app-header-padding {
  padding-left: 5% !important;
  padding-right: 5% !important;
}

.app-body-padding {
  padding-left: 4.5% !important;
  padding-right: 4.7% !important;
}

.app-footer-padding {
  padding-left: 6.7% !important;
  padding-right: 5.4% !important;
}

@media only screen and (max-width: 1023px) {
  .app-body-padding {
    padding-left: 3% !important;
    padding-right: 3% !important;
  }

  .app-header-padding {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }

  .app-footer-padding {
    padding-left: 3% !important;
    padding-right: 3% !important;
  }
}

.css-1t29gy6-MuiToolbar-root {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.header-padding {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
@media (min-width: 600px) {
  .css-191lty2 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url(./assets/images/PolygonDrpDown.png) no-repeat;
  width: 25px;
  height: 25px;
  border-width: thin;
  margin-top: 15%;
}

.width-90-custom {
  width: 90% !important;
}

.width-95-custom {
  width: 95% !important;
}

.width-70-custom {
  width: 70% !important;
}

.width-40-custom {
  width: 40% !important;
}

.width-20-custom {
  width: 20% !important;
}

.color-red-custom {
  color: #f44336 !important;
}

.avatar-custom-checkout {
  width: "50px";
  height: "50px";
}

.bg-dark-255 {
  background-color: rgba(255, 255, 255, 0.08) !important;
}

.drop-down-select-custom {
  border-radius: 8px !important;
  width: 100% !important;
  background: rgba(255, 255, 255, 0.08) !important;
  margin-top: 1px !important;
  padding: 0px 14px !important;
  color: grey !important;
  height: 44px;
}

.drop-down-select-custom-B-6 {
  background: #1f1f1f !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  height: 44px !important;
  color: #ffffff !important;
  /* opacity: 0.8; */
}

.custom-pagination-parts > nav {
  padding-left: 33% !important;
}

@media only screen and (max-width: 480px) {
  .drop-down-select-custom {
    border-radius: 8px !important;
    width: 100% !important;
    background: rgba(255, 255, 255, 0.08) !important;
    margin-top: 1px !important;
    padding: unset !important;
    color: grey !important;
    height: 44px;
  }
}
.drop-down-select-custom > fieldset {
  float: right !important;
  margin-top: 4px !important;
}
.drop-down-select-custom > input {
  width: 200px;
}
/* Global compoent CSS - Dropdown */
.css-hdw1oc {
  float: right !important;
}

.custom-mypage-a12-input {
  background: #1f1f1f !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  height: 44px !important;
}

/** Pls, don't remove this CSS, its applicable throughout the app */
.MuiDialog-paper {
  background: rgba(31, 31, 31, 0.75) !important;
  backdrop-filter: blur(81.5485px) !important;
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 14px !important;
}

.custom-color-d11-ff {
  color: rgba(255, 255, 255, 0.8) !important;
  font-weight: 500 !important;
}

.custom-d11-input {
  background: #1f1f1f !important;
  border: 2px solid rgba(255, 255, 255, 0.3) !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  height: 40px !important;
  padding-left: 0.5rem;
}

.container-custom {
  position: relative;
  text-align: center;
}

.top-right {
  position: absolute;
  top: -5px;
  right: 2px;
  border-radius: 50%;
}

#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(12, 12, 12, 0.457);
  z-index: 9999;
  display: block;
  text-align: center;
}
#cover-spin img {
  vertical-align: middle;
  text-align: center;
  margin-top: 22%;
}
.input-valid-form button {
  background-color: #e02d2d;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}
.pl-custom-css {
  padding-left: 1rem;
  padding-right: 1rem;
}
.text-hidden-custom-sidemenu {
  min-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-hidden-custom-parts-select {
  width: 350px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.align-items-center {
  align-items: center;
}

/* Favourite Item class */
.fav-cart-icon {
  opacity: 100;
  color: #e55c5c;
}

.img.responsive-checkoutd-block.cursor {
  width: 100%;
  height: 168px;
  border-radius: 8px;
}

/* Resposive styles */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .ToastView {
    padding: 5px 0px 5px 10px;
    width: -webkit-fill-available;
    border: 1px solid #000000;
    border-radius: 4px;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 1rem;
  }

  .img.responsive-cartd-block.cursor {
    width: 100%;
    height: 65px;
  }
  .img.responsive-checkoutd-block.cursor {
    width: 100%;
    height: 65px;
    border-radius: 8px;
  }

  .image-height-126 {
    height: 124px;
    width: 165px;
    border-radius: 8px;
    object-fit: cover;
  }
  /* B6 screen sold out label */

  .soldout-style {
    width: 69px;
    height: 20px;
    white-space: nowrap;
    display: inline;
    position: absolute;
    top: 10px;
    left: 10px;
    padding-right: 0.2rem;
    padding-left: 0.2rem;
    background: #e02d2d;
    border-radius: 3px;
  }

  .order-imaged-block {
    width: 100%;
    height: 131px;
    border-radius: 8px;
    object-fit: cover;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .ToastView {
    padding: 5px 0px 5px 10px;
    width: -webkit-fill-available;
    border: 1px solid #000000;
    border-radius: 4px;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 1rem;
  }

  .order-imaged-block {
    width: 100%;
    height: 131px;
    border-radius: 8px;
    object-fit: cover;
  }
  /* B6 screen sold out label */
  .soldout-style {
    width: 69px;
    height: 20px;
    white-space: nowrap;
    display: inline;
    position: absolute;
    top: 10px;
    left: 10px;
    padding-right: 0.2rem;
    padding-left: 0.2rem;
    background: #e02d2d;
    border-radius: 3px;
  }
  .img.responsive-checkoutd-block.cursor {
    height: 65px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .ToastView {
    padding: 5px 0px 5px 10px;
    width: -webkit-fill-available;
    border: 1px solid #000000;
    border-radius: 4px;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 1rem;
  }

  .order-imaged-block {
    width: 100%;
    height: 131px;
    border-radius: 8px;
    object-fit: cover;
  }

  img.responsive-cartd-block.cursor {
    height: 168px;
  }
  img.responsive-checkoutd-block.cursor {
    height: 168px;
  }
}

/* Large devices (laptops/desktops, 900px and up) */
@media only screen and (min-width: 900px) {
  .ToastView {
    padding: 5px 100px 5px 10px;
    border: 1px solid #000000;
    border-radius: 4px;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 5rem;
    width: auto;
  }

  .img.responsive-checkoutd-block.cursor {
    width: 100%;
    height: 168px;
    border-radius: 8px;
  }
  .img.responsive-cartd-block.cursor {
    width: 100%;
    height: 168px;
  }
  /* B6 screen sold out label */
  .soldout-style {
    width: 69px;
    height: 20px;
    white-space: nowrap;
    display: inline;
    position: absolute;
    top: 10px;
    left: 10px;
    padding-right: 0.2rem;
    padding-left: 0.2rem;
    background: #e02d2d;
    border-radius: 3px;
  }

  .order-imaged-block {
    width: 100%;
    height: 131px;
    border-radius: 8px;
    object-fit: cover;
  }
}

/* MUI md break point */
@media only screen and (max-width: 900px) {
  .font-size-18-16-variable {
    font-size: 16px !important;
  }

  .font-size-16-14-variable {
    font-size: 14px !important;
  }

  .navbar-responsive-padding {
    padding: 24px 16px 24px 16px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .ToastView {
    padding: 5px 100px 5px 10px;
    border: 1px solid #000000;
    border-radius: 4px;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 5rem;
    width: auto;
  }


  .order-imaged-block {
    width: 100%;
    height: 131px;
    border-radius: 8px;
    object-fit: cover;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .ToastView {
    padding: 5px 100px 5px 10px;
    border: 1px solid #000000;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 6rem;
    width: auto;
  }


  .order-imaged-block {
    width: 100%;
    height: 131px;
    border-radius: 8px;
    object-fit: cover;
  }
}

.ToastView .icon {
  font-family: "SF Compact";
  font-style: normal;
  font-weight: 656;
  font-size: 1.25rem;
  color: #ffffff;
}

.ToastView .textStyle {
  font-family: "Noto Sans CJK JP";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  color: #fafafa;
  display: flex;
  align-items: center;
}

.custom-font-hover:hover {
  background-color: rgba(0, 0, 0, 0);
  color: rgba(229, 92, 92, 1);
  text-decoration: underline;
  cursor: pointer;
}

.maker-search-img {
  width: unset;
  height: auto;
}
@media only screen and (min-width: 1200px) {
  .soldout-style {
    width: 72px;
    height: 20px;
    white-space: nowrap;
    display: inline;
    position: absolute;
    top: 10px;
    left: 10px;
    padding-right: 0.2rem;
    padding-left: 0.2rem;
    background: #e02d2d;
    border-radius: 3px;
  }
}
@media only screen and (min-width: 900px) {
  .ProductPreview-soldOut {
    width: 138px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    left: 15px;
    padding-right: 0.2rem;
    padding-left: 0.2rem;
    background: #e02d2d;
    border-radius: 3px;
  }
}

.item {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.item.visible {
  max-height: 500px; /* or any height that's larger than your items */
}

.MuiAccordionSummary-content {
  max-width: calc(100% - 24px); /* 24px is size of mui accordion expand icon */
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
